<template>
  <div>
    <!-- 审核中1 -->
    <withdrawal-table :auditStatus="1"></withdrawal-table>
  </div>
</template>
<script>
import withdrawalTable from '@/components/supplier/withdrawalTable.vue'
export default {
  components: { withdrawalTable },
  data() {
    return {
      
    }
  }
}
</script>
<style lang="scss" scoped>

</style>